import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Tracker from './Tracker';
import OverlayPage from './OverlayPage';
import { AppProvider } from './AppContext';
import './styles/main.scss';

const App = () => {
  const [milesWalked, setMilesWalked] = useState(0);
  const [isRunning] = useState(false);

  return (
    <React.StrictMode>
      <AppProvider value={{ milesWalked, setMilesWalked }}>
        <Router>
          <Routes>
            <Route path="/" element={<Tracker />} />
            <Route
              path="/overlay"
              element={<OverlayPage isRunning={isRunning} />}
            />
          </Routes>
        </Router>
      </AppProvider>
    </React.StrictMode>
  );
};

export default App;
