import React, { useState, useEffect } from 'react';

const Tracker = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [milesWalked, setMilesWalked] = useState(0);
  const [pace, setPace] = useState(2);
  const [tempMilesWalked, setTempMilesWalked] = useState(0);
  const [tempPace, setTempPace] = useState(2);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching tracking data...');
        const response = await fetch(`${backendURL}/api/tracking`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        console.log('Received tracking data:', responseData);

        if (responseData) {
          setIsRunning(responseData.isRunning);
          setMilesWalked(responseData.milesWalked);
          setPace(responseData.pace);
          setTempMilesWalked(responseData.milesWalked);
          setTempPace(responseData.pace);
        }
      } catch (error) {
        console.error('Error fetching tracking data:', error);
      }
    };

    const fetchIsRunning = async () => {
      try {
        console.log('Fetching isRunning status...');
        const response = await fetch(`${backendURL}/api/tracking`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        console.log('Received isRunning status:', responseData.isRunning);

        if (responseData.isRunning !== isRunning) {
          setIsRunning(responseData.isRunning);
        }

        if (responseData.isRunning) {
          // Fetch full data if isRunning is true
          setMilesWalked(responseData.milesWalked);
          setPace(responseData.pace);
        }
      } catch (error) {
        console.error('Error fetching isRunning status:', error);
      }
    };

    fetchData(); // Fetch full data on initial load

    const intervalId = setInterval(fetchIsRunning, 3000); // Fetch isRunning status every 3 seconds

    return () => clearInterval(intervalId);
  }, [backendURL, isRunning]);

  const updateValues = async (newValues) => {
    try {
      console.log('Updating values on the server...');
      const response = await fetch(`${backendURL}/api/tracking/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          isRunning: newValues.isRunning ?? isRunning,
          milesWalked: newValues.milesWalked ?? milesWalked,
          pace: newValues.pace ?? pace,
        }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.success) {
        console.log('Values updated successfully on the server.');
        if (newValues.isRunning !== undefined) setIsRunning(newValues.isRunning);
        if (newValues.milesWalked !== undefined) setMilesWalked(newValues.milesWalked);
        if (newValues.pace !== undefined) setPace(newValues.pace);

        localStorage.setItem('isRunning', newValues.isRunning ?? isRunning);
        localStorage.setItem('milesWalked', newValues.milesWalked ?? milesWalked);
        localStorage.setItem('pace', newValues.pace ?? pace);
      } else {
        console.error('Failed to update values on the server:', data.error);
      }
    } catch (error) {
      console.error('Error updating values on the server:', error);
    }
  };

  const handleStartPauseClick = () => {
    const newIsRunning = !isRunning;
    updateValues({ isRunning: newIsRunning });
  };

  const handlePaceInputChange = (event) => {
    const newPace = parseFloat(event.target.value);
    if (!isNaN(newPace)) {
      setTempPace(newPace);
    }
  };

  const handleMilesWalkedInputChange = (event) => {
    const newMilesWalked = parseFloat(event.target.value);
    if (!isNaN(newMilesWalked)) {
      setTempMilesWalked(newMilesWalked);
    }
  };

  const handleUpdateClick = () => {
    updateValues({
      milesWalked: tempMilesWalked,
      pace: tempPace,
    });
  };

  const resetMilesWalked = () => {
    updateValues({ isRunning: false, milesWalked: 0 });
  };

  const openOverlayPage = () => {
    window.open('/overlay', '_blank');
  };

  return (
    <div className="app-container">
      <div className='tracker-layout'>
        <div className='hero'>
          <div className='content-block'>
            <div className='running-bald'>
              <img src={isRunning ? "/images/bromnrun.gif" : "/images/bromnrun-pause.png"} alt={isRunning ? "Running" : "Paused"} />
            </div>
            <h1 className='title'><span className='the-miles'>{milesWalked.toFixed(3)}</span> Miles Walked</h1>
          </div>
        </div>

        <div className='controls'>
          <div className='content-block'>
            <div className='toggle-pause'>
              <button
                id="startPauseButton"
                onClick={handleStartPauseClick}
                className={isRunning ? 'paused' : 'running'}
              >
                {isRunning ? 'Pause' : 'Start'}
              </button>
            </div>
            <div className='tracker-options'>
              <button onClick={resetMilesWalked}>Reset</button>
            </div>
            <div className='tracker-options'>
              <button onClick={openOverlayPage}>Open Overlay Page</button>
            </div>
          </div>
        </div>

        {isRunning ? null : (
          <div className='options'>
            <div className='title'>
              <h3>Options</h3>
            </div>

            <div className='content-block'>
              <div className='set-pace'>
                <div className='option'>
                  <label htmlFor="paceInput">Set Pace (mph):</label>
                  <input 
                    type="number"
                    id="paceInput"
                    value={tempPace}
                    onChange={handlePaceInputChange}
                    min="0"
                    step="0.1"
                    className='the-pace'
                  />
                </div>
              </div>

              <div className='edit-miles'>
                <div className='option'>
                  <label htmlFor="editableMilesInput">Set Starting Miles:</label>
                  <input
                    type="number"
                    id="editableMilesInput"
                    value={tempMilesWalked.toFixed(3).replace(/\.?0+$/, '')}
                    onChange={handleMilesWalkedInputChange}
                    min="0"
                    step="0.001"
                    className='starting-miles'
                  />
                  <button onClick={handleUpdateClick}>Update</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <footer>
        <div className='content-block'>
          <p>v 1.3</p>
        </div>
      </footer>
    </div>
  );
};

export default Tracker;
