import React, { useState, useEffect } from 'react';

const OverlayPage = () => {
  const [isRunning, setIsRunning] = useState(false); // Define isRunning state
  const [milesWalked, setMilesWalked] = useState(0);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchMilesWalked = async () => {
      try {
        const response = await fetch(`${backendURL}/api/tracking`);
        const data = await response.json();
        if (data && data.milesWalked !== undefined) {
          setMilesWalked(data.milesWalked);
          setIsRunning(data.isRunning); // Update isRunning based on data from the server
        } else {
          console.error('Invalid response from server');
        }
      } catch (error) {
        console.error('Error fetching miles walked:', error);
      }
    };

    const interval = setInterval(fetchMilesWalked, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [backendURL]); // useEffect dependency

  return (
    <div className='the-overlay'>
      <div className='the-runner'>
        <img src={isRunning ? "/images/bromnrun.gif" : "/images/bromnrun-pause.png"} alt={isRunning ? "Running" : "Paused"} />
      </div>
      <h1 className='overlay-counter'>{milesWalked ? milesWalked.toFixed(2) : 'Loading...'}</h1>
    </div>
  );
};

export default OverlayPage;
