// index.js
import React from 'react';
// import ReactDOM from 'react-dom';
import App from './App';
import { AppProvider } from './AppContext';
import { createRoot } from 'react-dom/client';

const rootElement = document.getElementById('root');

createRoot(rootElement).render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
);
