// AppContext.js
import React, { createContext } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [milesWalked, setMilesWalked] = React.useState(0);
  const [isRunning, setIsRunning] = React.useState(false); // Add isRunning state

  return (
    <AppContext.Provider value={{ milesWalked, setMilesWalked, isRunning, setIsRunning }}>
      {children}
    </AppContext.Provider>
  );
};